<template>
  <div>
    <div class="flex flex-start mb-20">
      <span class="mr-10">选择日期: </span>
      <a-range-picker v-model="chartRange" :disabled-date="disabledDate" :allow-clear="false"
                      class="mr-10"></a-range-picker>
      <a-button type="primary" :loading="chartLoading" @click="onSearchChart">查询</a-button>
      <span class="ml-20 flex flex-column flex-start block-height ">计费单价：0.02元/人/分钟</span>
    </div>

    <div class="flex flex-wrap align-stretch mb-20">
      <StatisticsCard :value="broadcastValue" class="m-10"/>
      <StatisticsCard :value="playbackValue" class="m-10"/>
      <StatisticsCard :value="frequencyValue" class="m-10"/>
    </div>

    <div class="mb-20">
      <LineBar ref="lineBarRef" :chart-data="chartData"/>
    </div>

    <a-form :form="form" layout="inline" class="mb-10">
      <a-form-item label="选择日期">
        <a-range-picker v-model="tableRange" :disabled-date="disabledDate" :allow-clear="false"></a-range-picker>
      </a-form-item>

      <a-form-item label="查询目标">
        <!--<SelectLiveCourse v-model="selectedLiveIds"/>-->
        <a-input v-model="liveCourseName" placeholder="请输入直播或课程名称" class="live-course-input"></a-input>
      </a-form-item>

      <a-form-item class="full-width flex flex-end">
        <a-button type="primary" :loading="loading" @click="onSearchTable">查询</a-button>
        <a-button class="mr-30" @click="reset">重置</a-button>
        <a-button type="primary" :loading="downloading" @click="exportExcel">导出</a-button>
      </a-form-item>
    </a-form>

    <div class="mb-20">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="easemob_id" class="table small-cell-table">
        <template slot="live_date" slot-scope="text">
          <div class="table-cell-wrapper">{{ formatDateTime(text) }}</div>
        </template>
        <template slot="action" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <a-button type="link" size="small" @click="viewDetail(record)">查询明细</a-button>
          </div>
        </template>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment';
import { getBroadcastBarData, getBroadcastList, getBroadcastListDownload } from '@/api/data-statistics/Index';
import { broadcastDetail } from '@/utils/Navs/DataStatisticsNavs';

import Pagination, { getPagination } from '@/components/Pagination';
import LineBar from './components/LineBar';
// import SelectLiveCourse from './components/SelectLiveCourse';
import StatisticsCard from './components/StatisticsCard';
import Common from '@/utils/Common';

const columns = [
  {
    width: '60px',
    align: 'center',
    dataIndex: 'easemob_id',
    key: 'easemob_id',
    title: '直播ID',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'live_name',
    key: 'live_name',
    title: '直播名称',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'course_name',
    key: 'course_name',
    title: '绑定课程',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'live_date',
    key: 'live_date',
    title: '直播日期',
    scopedSlots: { customRender: 'live_date' },
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'totalMember',
    key: 'totalMember',
    title: '直播观看人数',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'duration_times',
    key: 'duration_times',
    title: '开播时长（分）',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'totalLiveTimes',
    key: 'totalLiveTimes',
    title: '观看直播累计时长（分）',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'totalPlayMember',
    key: 'totalPlayMember',
    title: '点播观看人数',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'totalPlayTimes',
    key: 'totalPlayTimes',
    title: '回看点播累计时长（分）',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'money',
    key: 'money',
    title: '总费用（元）',
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'Overview',
  components: { Pagination, LineBar, /* SelectLiveCourse, */ StatisticsCard },
  data() {
    return {
      titles: [{ title: '直播数据总览' }],
      chartLoading: false,
      loading: false,
      downloading: false,

      broadcastValue: { title: '直播累计时长：', val: 0, unit: '分钟', subTitle: '累计费用：', subVal: 0, subUnit: '元' },
      playbackValue: { title: '回看点播累计时长：', val: 0, unit: '分钟', subTitle: '累计费用：', subVal: 0, subUnit: '元' },
      frequencyValue: { title: '累计直播场次：', val: 0, unit: '场' },

      form: this.$form.createForm(this, { name: 'coordinated' }),
      chartRange: [],
      tableRange: [],
      selectedLiveIds: [],
      liveCourseName: '',

      chartData: {},

      columns: columns,
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    const chartRange = this.$store.state.Broadcast.chartRange || [];
    if (chartRange[0] && chartRange[1]) {
      this.chartRange = [...chartRange];
    } else {
      this.chartRange = this.getDefaultRange();
    }

    const tableRange = this.$store.state.Broadcast.tableRange || [];
    if (tableRange[0] && tableRange[1]) {
      this.tableRange = [...tableRange];
    } else {
      this.tableRange = this.getDefaultRange();
    }
    this.liveCourseName = this.$store.state.Broadcast.liveCourseName || '';
    // this.selectedLiveIds = this.$store.state.Broadcast.liveIds || [];

    this.onSearchChart();
    this.onSearchTable();
  },
  methods: {
    getDefaultRange() {
      return [
        moment().subtract(3, 'months').startOf('day'),
        moment().endOf('day'),
      ];
    },

    disabledDate(current) {
      return current.isBefore(moment('2021-02-26', 'YYYY-MM-DD'));
    },

    formatDateTime(time) {
      return Common.formatDateTime(time * 1000);
    },

    onSearchChart() {
      this.$store.commit('Broadcast/updateChartRange', this.chartRange);

      this.getBroadcastBarData();
    },
    saveImage() {
      this.$refs.lineBarRef?.saveImage && this.$refs.lineBarRef.saveImage();
    },
    async getBroadcastBarData() {
      if (!this.chartRange || !this.chartRange[0] || !this.chartRange[1]) {
        return this.$message.info('请选择时间段');
      }
      if (this.chartLoading) {return;}
      this.chartLoading = true;

      const params = {
        start: this.chartRange[0].format('YYYY-MM-DD'),
        end: this.chartRange[1].format('YYYY-MM-DD'),
      };

      const data = await getBroadcastBarData(params).finally(() => this.chartLoading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '获取图表失败');
      }
      this.chartData = {
        date: data.data.data || [],
        series: data.data.series || [],
      };

      const meta = data?.meta || {};
      const broadcast = meta?.total_live || {};
      const playback = meta?.total_play || {};
      this.broadcastValue = { ...this.broadcastValue, val: broadcast.total || 0, subVal: broadcast.money || 0 };
      this.playbackValue = { ...this.playbackValue, val: playback.total || 0, subVal: playback.money || 0 };
      this.frequencyValue = { ...this.frequencyValue, val: meta.number || 0 };
    },

    async getData(page, pageSize) {
      if (!this.tableRange || !this.tableRange[0] || !this.tableRange[1]) {
        return this.$message.info('请选择时间段');
      }

      if (this.loading) {return;}
      this.loading = true;

      const params = {
        start: this.tableRange[0].format('YYYY-MM-DD'),
        end: this.tableRange[1].format('YYYY-MM-DD'),
        live_name: this.liveCourseName,
        page,
        per_page: pageSize,
      };

      const data = await getBroadcastList(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '查询数据失败');
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    onSearchTable() {
      this.$store.commit('Broadcast/updateTableRange', this.tableRange);
      this.$store.commit('Broadcast/updateLiveCourseName', this.liveCourseName);
      // this.$store.commit('Broadcast/updateLiveIds', this.selectedLiveIds);

      this.getData(1, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },

    reset() {
      this.tableRange = this.getDefaultRange();
      this.liveCourseName = '';
      // this.selectedLiveIds = [];

      this.$store.commit('Broadcast/updateTableRange', this.tableRange);
      this.$store.commit('Broadcast/updateLiveCourseName', this.liveCourseName);
      // this.$store.commit('Broadcast/updateLiveIds', this.selectedLiveIds);
    },
    exportExcel() {
      if (!this.tableRange || !this.tableRange[0] || !this.tableRange[1]) {
        return this.$message.info('请选择时间段');
      }

      if (this.downloading) {return;}
      this.downloading = true;

      const params = {
        start: this.tableRange[0].format('YYYY-MM-DD'),
        end: this.tableRange[1].format('YYYY-MM-DD'),
        page: this.pagination.current,
        per_page: this.pagination.pageSize,
        live_name: this.liveCourseName,
        is_down: 1,
      };

      getBroadcastListDownload(params).finally(() => this.downloading = false).catch(() => this.downloading = false);
    },

    viewDetail(record) {
      this.$router.push({ path: broadcastDetail[0].path, query: { id: record.easemob_id } });
    },
  },
};
</script>

<style scoped lang="scss">
.live-course-input {
  min-width: 220px;
}
</style>
