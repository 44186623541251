import request from '@/utils/Request'

// 下载记录列表
export function getDownloadRecord(params) {
  return request({
    url: `/datacenter/admin/downloadStatistics/getDownLogs`,
    method: 'get',
    params
  })
}

// 敏感信息下载记录--重新申请下载
export function downloadRetry(data) {
  return request({
    url: `/datacenter/admin/downloadStatistics/reapply`,
    method: 'post',
    data
  })
}

