<template>
  <div class="data-statistics-broadcast-detail-user full-page">
    <div class="flex flex-start flex-wrap ph-20 mb-20">
      <span class="mr-10 flex-none">查询目标: </span>
      <a-input v-model="filterTxt" :allow-clear="true" placeholder="请输入学员姓名"
               class="mr-30 flex-none search-input"></a-input>

      <a-button type="primary" :loading="loading" @click="onSearch">查询</a-button>
      <a-button class="mr-30" @click="reset">重置</a-button>
      <a-button type="primary" :loading="downloading" @click="exportExcel2">导出</a-button>
      <a-button @click="back">返回</a-button>
    </div>

    <div class="flex flex-start flex-wrap mb-20">
      <StatisticsCard :value="broadcastValue" class="m-10"/>
      <StatisticsCard :value="playbackValue" class="m-10"/>
      <span class="m-10 flex flex-column flex-start flex-none block-height ">计费单价：0.02元/人/分钟</span>
    </div>

    <div class="mb-20 block">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="number" class="table small-cell-table">
        <template slot="idcard" slot-scope="text, record">
          <div class="flex">
            <CiphertextViewer :user-id="record.user_id" :data="record" field="idcard"/>
          </div>
        </template>
        <template slot="mobile" slot-scope="text, record">
          <div class="flex">
            <CiphertextViewer :user-id="record.user_id" :data="record" field="mobile"/>
          </div>
        </template>

        <template slot="action" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <a-button type="link" size="small" @click="viewDetail(record)">查询明细</a-button>
          </div>
        </template>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <DownloadReasonModal ref="downloadReasonModalRef" @saved="exportExcel"/>
  </div>
</template>

<script>
import {getBroadcastUserDetail, getBroadcastUserDetailDownload} from '@/api/data-statistics/Index';
import Common from '@/utils/Common';

import Pagination, {getPagination} from '@/components/Pagination';
import StatisticsCard from './components/StatisticsCard';

import CiphertextViewer from '@/components/viewer/CiphertextViewer.vue'
import DownloadReasonModal from '@/views/download/record/components/DownloadReasonModal.vue'

const columns = [
  {
    width: '50px',
    align: 'center',
    dataIndex: 'number',
    key: 'number',
    title: '序号',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'user_name',
    key: 'user_name',
    title: '姓名',
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'mobile',
    key: 'mobile',
    title: '电话',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'org_name',
    key: 'org_name',
    title: '所属经销商',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'post_name',
    key: 'post_name',
    title: '岗位',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'join_time',
    key: 'join_time',
    title: '进入时间',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'leave_time',
    key: 'leave_time',
    title: '离开时间',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'broadcast_time',
    key: 'broadcast_time',
    title: '累计直播时长',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'playback_time',
    key: 'playback_time',
    title: '累计点播时长',
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'login_type',
    key: 'login_type',
    title: '设备类型',
  },
];

export default {
  name: 'User',
  components: {
    CiphertextViewer,
    DownloadReasonModal,
    Pagination,
    StatisticsCard
  },
  data() {
    return {
      loading: false,
      downloading: false,

      broadcastValue: {title: '观看直播累计时长：', val: 0, unit: '分钟', subTitle: '累计费用：', subVal: 0, subUnit: '元'},
      playbackValue: {title: '回看点播累计时长：', val: 0, unit: '分钟', subTitle: '累计费用：', subVal: 0, subUnit: '元'},

      filterTxt: '',

      data: [],
      pagination: getPagination(),
      columns: columns,

      filter: '',
    };
  },
  created() {
    this.id = this.$route.query?.id || '';
    this.filterTxt = this.$store.state.Broadcast.userName || '';

    this.id && this.onSearch();
  },
  methods: {
    back() {
      this.$router.back();
    },

    async getData(page, pageSize) {
      if (this.loading) {return;}
      this.loading = true;

      const params = {
        id: this.id,
        username: this.filterTxt,
        page,
        per_page: pageSize,
      };

      const data = await getBroadcastUserDetail(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '查询人员明细失败');
      }

      const result = data?.data || [];
      result.forEach((i) => {
        if (i.is_live === 1) {
          i.broadcast_time = i.watch_time;
          i.playback_time = '--';
        } else {
          i.playback_time = i.watch_time;
          i.broadcast_time = '--';
        }
      });
      this.data = result;

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);

      const broadcast = data?.meta?.total_live || {};
      const playback = data?.meta?.total_play || {};

      this.broadcastValue = {...this.broadcastValue, val: broadcast.total || 0, subVal: broadcast.money || 0};
      this.playbackValue = {...this.playbackValue, val: playback.total || 0, subVal: playback.money || 0};
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },


    onSearch() {
      this.$store.commit('Broadcast/updateUserName', this.filterTxt);

      this.getData(1, this.pagination.pageSize);
    },
    reset() {
      this.filterTxt = '';
      this.$store.commit('Broadcast/updateUserName', this.filterTxt);
    },

    exportExcel2() {
      this.$refs.downloadReasonModalRef.show()
    },
    exportExcel(evt) {
      if (this.downloading) {return;}
      this.downloading = true;

      const params = {
        id: this.id,
        username: this.filterTxt,
        page: this.pagination.current,
        per_page: this.pagination.pageSize,
        is_down: 1,
      };

      Object.keys(evt || {}).forEach((key) => params[key] = evt[key])

      getBroadcastUserDetailDownload(params).then(() => {
        this.downloading = false;
        this.$refs.downloadReasonModalRef.close()
        this.$message.success(evt.down_type === 1 ? '申请成功' : '下载成功')
      }).catch(() => {
        this.downloading = false;
        this.$refs.downloadReasonModalRef.stopLoading()
      }).finally(() => this.downloading = false);
    },

  },
};
</script>

<style scoped lang="scss">
.data-statistics-broadcast-detail-user {
  .search-input {
    width: 260px;
  }
}
</style>
